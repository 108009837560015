import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../Navbars/Sidebar/Sidebar';
import Navbar from '../Navbars/Navbar/Navbar';
import loyalty from '../Api/Loyalty';
import './Settings.css';
import '../MainCss/Main.css';
import ToastError from '../Toasts/ToastError';

const Profile = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    picture: '',
  });

  const { setting } = useParams();

  useEffect(() => {
    (async () => {
      try {
        if (setting !== 'profiel' && setting !== 'email' && setting !== 'wachtwoord') {
          navigate(`/puntensaldo`);
        }

        const response = await loyalty.get(`auth?ApiKey=${cookies.get('token')}`);
        setUser(response.data);
        setIsLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          ToastError('U moet hiervoor ingelogd zijn.');
        } else {
          console.warn(error);
        }
        cookies.remove('token', {path: '/'});
        navigate('/');
      }
    })()
  }, []);

  return (
    <div className='d-flex flex-nowrap'> 
      <Sidebar/>
      <div className='d-flex flex-column col-10'>
        <Navbar title={'INSTELLINGEN'}/>
        <ToastContainer/>
        <div className='d-flex'>
          {isLoading ? (
            <div className='mainBox'>
              <div className="d-flex px-3 py-2 mt-4 mx-4 col-10 justify-content-around flex-column position-absolute">
                <div className="spinner-border text-primary spinner-border"></div>
              </div>
            </div>
          ) : (
            <div className='mainBox'>
              <div className='d-flex px-3 py-2 mt-4 mx-4 col-10 justify-content-around flex-column position-absolute'>
                <div className='d-flex flex-row parentBoxSettings'>
                  <div className='whiteBox profileBox'>
                    {setting === 'profiel' && <ProfileComp user={user}/>}
                    {setting === 'email' && <Email user={user}/>}
                    {setting === 'wachtwoord' && <Password user={user}/>}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const ProfileComp = (props) => {
  const [values, setValues] = useState(props.user);

  const handleFileChange = (event) => {
    const picture = event.target.files[0];
    if (picture === undefined) {
      return;
    }

    if (picture.type !== 'image/png' && picture.type !== 'image/jpeg' && picture.type !== 'image/gif') {
      event.target.value = null;
      ToastError('Je mag alleen een: png, jpeg of gif uploaden');
      return;
    }
    
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (picture.size > maxSize) {
      event.target.value = null;
      ToastError('Bestand mag niet groter zijn dan 5mb');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      setValues({...values, picture: base64String});
    };
    reader.readAsDataURL(picture);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await toast.promise(
        loyalty.put(`users`, {
          id: values.id,
          first_name: values.first_name,
          last_name: values.last_name,
          picture: values.picture,
        }),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
          error: 'Er is iets fout gegaan tijdens het opslaan'
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );
      window.location.reload();
    } catch (error) {
      console.warn(error);
    }
  }

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
  }

  return (
    <form onSubmit={handleSubmit} className='ms-4 mt-3'>
      <h2 className='mb-3'>Profiel</h2>
      <div className='mb-3'>
        <span>Voornaam</span>
        <input type='text' value={values.first_name ? values.first_name : ''} onChange={handleChange} name='first_name' autoComplete='given-name' className='form-control rounded-pill'/>
      </div>

      <div className='mb-3'>
        <span>Achternaam</span>
        <input type='text' value={values.last_name ? values.last_name : ''} onChange={handleChange} name='last_name' autoComplete='family-name' className='form-control rounded-pill'/>
      </div>

      <div className='mb-3'>
        <span>Profielfoto</span>
        <input onChange={handleFileChange} name='picture' type="file" accept='.png,.jpg,.gif' placeholder='Nog geen foto geselecteerd' className="form-control rounded-pill"/>
      </div>

      <button type='submit' className="btn btn-primary me-4 ps-4 pe-4 buttonsAddCustomers" role="button">Profiel opslaan</button>
    </form>
  );
}

const Email = (props) => {
  const [values, setValues] = useState(props.user);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await toast.promise(
        loyalty.put(`editEmail`, {
          id: values.id,
          currentPassword: currentPassword,
          newEmail: newEmail,
        }),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );
      window.location.reload();
    } catch (error) {
      if (error.response.status === 401) {
        ToastError(error.response.data.error);
      }
      console.warn(error.response);
    }
  }

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
  }

  return (
    <form onSubmit={handleSubmit} className='ms-4 mt-3'>
      <h2 className='mb-3'>E-mailadres wijzigen</h2>
      <div className='mb-3'>
        <span>Huidige e-mailadres</span>
        <input type='email' defaultValue={values.email} disabled className='form-control rounded-pill'/>
      </div>

      <div className='mb-3'>
        <span>Huidige wachtwoord</span>
        <input type='password' required value={currentPassword} onChange={event => setCurrentPassword(event.target.value)} className='form-control rounded-pill'/>
      </div>

      <div className='mb-3'>
        <span>Nieuw e-mailadres</span>
        <input type='email' required value={newEmail} onChange={event => setNewEmail(event.target.value)} className='form-control rounded-pill'/>
      </div>

      <button type='submit' className="btn btn-primary me-4 ps-4 pe-4 buttonsAddCustomers" role="button">E-mailadres bijwerken</button>
    </form>
  );
}

const Password = (props) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await toast.promise(
        loyalty.put(`changePassword`, {
          id: props.user.id,
          currentPassword: currentPassword,
          newPassword: newPassword,
          checkPassword: checkPassword,
        }),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );
      window.location.reload();
    } catch (error) {
      if (error.response.status === 401) {
        ToastError(error.response.data.error);
      }
      console.warn(error.response);
    }
  }

  return (
    <form onSubmit={handleSubmit} className='ms-4 mt-3'>
      <h2 className='mb-3'>Wachtwoord wijzigen</h2>
      <div className='mb-3'>
        <span>Huidige wachtwoord</span>
        <input type='password' required value={currentPassword} onChange={event => setCurrentPassword(event.target.value)} className='form-control rounded-pill'/>
      </div>

      <div className='mb-3'>
        <span>Nieuw wachtwoord</span>
        <input type='password' required value={newPassword} onChange={event => setNewPassword(event.target.value)} className='form-control rounded-pill'/>
      </div>

      <div className='mb-3'>
        <span>Bevestig nieuw wachtwoord</span>
        <input type='password' required value={checkPassword} onChange={event => setCheckPassword(event.target.value)} className='form-control rounded-pill'/>
      </div>

      <button className="btn btn-primary me-4 ps-4 pe-4 buttonsAddCustomers" role="button">Wachtwoord veranderen</button>
    </form>
  );
}

export default Profile;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import defaultLogo from './logoTechDogDark.png';
import loyalty from '../Api/Loyalty';
import './Login.css'
import ToastError from '../Toasts/ToastError';

const Login = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);


  const expirationDate = new Date();
  expirationDate.setHours(23, 59, 59, 0); // Set the time to 23:59:59.000

  const [logo, setLogo] = useState(undefined);

  useEffect(() => {
    (async () => {
      try {
        const response = await loyalty.get(`logo`);
        setLogo(response.data.logo);
      } catch (error) {
        if (error.response.status === 404) {
          setLogo(null);
        } else {
          console.warn(error);
        }
      }
    })()
    setTimeout(() => {
      setIsLoading(false);
    }, 150); // Time for the image to render
  }, []);


  useEffect(() => {
    (async () => {
      const key = cookies.get('token');
      if (key) {
        try {
          await loyalty.get(`auth?ApiKey=${key}`);
          navigate('/puntensaldo');
        } catch (error) {
          if (error.response.status !== 401) {
            console.warn(error.response.data);
          }
          cookies.remove('token', {path: '/'});
        }
      }
    })()
  }, []);
  
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await loyalty.get(`auth?email=${email}&password=${password}`);
      cookies.set('token', response.data.api_token, { path: '/', expires: expirationDate });
      navigate('/puntensaldo');
    } catch (error) {
      ToastError(error.response.data.error);
    }
  }
  
  return (
    <div className='backgroundColor'>
      <div className='d-flex justify-content-center formContainer'>
        <ToastContainer/>
          {!isLoading ? (
            <form onSubmit={handleLogin}>
              <div className='mb-5 d-flex justify-content-center'>
                {logo !== null ? (
                  <img className='img-fluid logoAdmin' src={logo  && `data:image/png;base64,${logo}`}/>
                ) : (
                  <img className='img-fluid logoAdmin' src={defaultLogo} alt='Logo'></img>
                )}
              </div>
              <div className="mb-3 input-group-sm d-flex justify-content-center">
                <input type="email" required onChange={e => setEmail(e.target.value)} className="inputField inputStyle" placeholder="Gebruikersnaam"/>
              </div>
              <div className="mb-3 input-group-sm d-flex justify-content-center">
                <input type="password" required onChange={e => setPassword(e.target.value)} className="inputField inputStyle" placeholder="Wachtwoord"/>
              </div>
              <div className='mb-3 input-group-sm'>
                <button type="submit" className="btn w-100 rounded-pill loginButton">Login</button>
              </div>
            </form>
          ) : (
            <div className='mb-5 d-flex justify-content-center'>
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary spinner-border-sm">
                </div>
              </div>
            </div>
          )}
      </div>
      {error}
    </div>
  );
}

export default Login;
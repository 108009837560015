import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router';
import { Modal } from 'bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import loyalty from './Api/Loyalty';
import Zoekicon from './Icons/Zoekicon.png';
import "./table/Table.css"
import './MainCss/Main.css';
import "./Customers/Consumers/Consumers.css";
import ToastError from './Toasts/ToastError';

const Searchfunction = (props) => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [searchedBarcode, setSearchedBarcode] = useState('');
  const [customers, setCustomers] = useState({data: []});
  const [showDropdown, setShowDropdown] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    if (props.onBarcodeChange) {
      props.onBarcodeChange(searchedBarcode); // call the callback function with the new state
    }
  }, [searchedBarcode]);

  useEffect(() => {
    if (searchTerm === '') {
      setShowDropdown(false);
      setLoading(false);
      return;
    }
    
    setLoading(true);
    setShowDropdown(true);

    const getCustomer = setTimeout(async () => {
      try {
        const response = await loyalty.get(`customers?searchTerm=${searchTerm}&page_size=5&page=1&noSearch=${[
          'id',
          'type',
          'status',
          'created_at',
          'updated_at',
          'newsletter_subscription',
          'mail_subscription',
        ]}`);

        setCustomers(response.data);
      } catch (error) {
        console.warn(error);
        if (error.response.status === 401) {
          cookies.remove('token', {path: '/'});
          navigate('/');
        }
      }
      setLoading(false);
    }, 750);

    return () => clearTimeout(getCustomer);
  }, [searchTerm]);

  const handleBarcode = async (event) => {
    event.preventDefault();

    searchRef.current.blur() // unselects the input field
    const searchTermInt = parseInt(searchTerm);
  
    if (isNaN(searchTermInt) || searchTermInt.toString().length !== 13) {
      searchRef.current.focus(); // selects the input field
      setSearchTerm('');
      ToastError(`Ongeldige barcode`);
      return;
    }

    const barcodesResponse = await loyalty.get(`barcodes?barcode=${searchTerm}`);

    if (barcodesResponse.data.length === 0) {
      searchRef.current.focus()
      setSearchTerm('');
      ToastError(`Ongeldige barcode`);
      return;
    }

    const customersResponse = await loyalty.get(`customers?barcode=${searchTerm}`);
    
    if (customersResponse.data.length === 0 && props.getCustomer) {
      searchRef.current.focus()
      setSearchTerm('');
      ToastError(`Deze barcode: ${searchTerm} is nog niet gekoppeld aan een klant`);
      return;
    } else if (customersResponse.data.length === 0) { // Opens new modal
      setSearchedBarcode(searchTerm);
      setSearchTerm('');
      setModal(new Modal(document.getElementById('Modal')));
      setModal((modal) => {
        modal.show();
        return modal;
      });
      return;
    }

    if (props.getCustomer) {
      setSearchTerm('');
      props.getCustomer(customersResponse.data[0]);
      return;
    }

    const customer = customersResponse.data[0];
    const customerType = customer.type === 0 ? 'consument' : 'bedrijf';
    navigate(`/klanten/${customerType}/${customer.barcode}`);
    window.location.reload();
  }

  const handleCustomer = (customer) => {
    if (props.getCustomer) {
      setSearchTerm('');
      props.getCustomer(customer);
    } else {
      navigate(`/klanten/${customer.type === 0 ? 'consument' : 'bedrijf'}/${customer.barcode}`);
      window.location.reload();
    }
  }

  return (
    <div>
      <form onSubmit={handleBarcode} className='d-flex flex-nowrap'>
        <input
          className='mt-2 inputField inputFieldWidth heightInputs'
          type="text"
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
          autoFocus={props.select === true}
          ref={searchRef}
          placeholder='Scan kaart of zoek klant'
        />
        <img src={Zoekicon} alt="Zoekicon" className='iconsNav text-secondary me-1 mt-4 iconLineHeight imgSearch' />
      </form>
      {loading &&
        <div>
          <div className="spinner-border text-primary spinner-border-sm" role="status"></div>
        </div>
      }                        

      {showDropdown && !loading && customers.data.length > 0 &&
        <ul className="list-group inputFieldWidth">
          {customers.data.map(item => (
            <li
              key={item.id}
              className="list-group-item hover d-flex justify-content-around"
              onClick={() => handleCustomer(item)}
            >
              <span className='col-3 itemsModalSearch'>{item.first_name ? `${item.first_name} ` : ''} {item.last_name ?? ''}</span>
              <span className='col-3 itemsModalSearch '>{item.email}</span>
              <span className='col-4 itemsModalSearch'>{item.address_streetname ? `${item.address_streetname} ` : ' '}
              {item.address_house_number ? `${item.address_house_number}` : ''}
              {item.address_addition ? `${item.address_addition}, ` : ' '}
              {item.address_postalcode ? `${item.address_postalcode}, ` : ' '}
              {item.address_city ?? ''}</span>
            </li>
          ))}
        </ul>
      }

      {showDropdown && !loading && customers.data.length === 0 && searchTerm !== '' &&
        <span className='text-danger'>Geen klanten gevonden</span>
      }
    </div>
  );
}

export default Searchfunction;
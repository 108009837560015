import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

const NotFound = () => {
  const cookies = new Cookies();
  const [link, setLink] = useState('/');

  useEffect(() => {
    if (cookies.get('token')) {
      setLink('/puntensaldo');
    }
  })

  return (
    <div className='m-5 text-center'>
      <h1>404</h1>
      <h1>Oop's looks like this page does not exist</h1>
      <p>Go <Link to={link}>back</Link></p>
    </div>
  );
}

export default NotFound;
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../Navbars/Sidebar/Sidebar';
import Navbar from '../Navbars/Navbar/Navbar';
import loyalty from '../Api/Loyalty';
import './Settings.css';
import '../MainCss/Main.css';
import ToastError from '../Toasts/ToastError';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'

const Settings = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const search = window.location.search.replace('?', '');

  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState({
    exchange_value: '',
    points_value: '',
    default_option: '',
  });

  useEffect(() => {
    if (search !== 'punten' && search !== 'bedrijf') {
      navigate(`/instellingen?punten`);
    }
    
    (async () => {
      try {
        const response = await loyalty.get(`settings`);
        const data = response.data[0];
        if (response.data.length > 0) {
          const euros = data.default_euros ? data.default_euros.toFixed(2) : '';
          setSettings({...data, default_euros: euros});
        }
        setIsLoading(false);
      } catch (error) {
        console.warn(error);
        if (error.response.status === 401) {
          ToastError('Je moet ingelogd zijn om deze actie uit te voeren. Log alsjeblieft in om door te gaan.')
        } else {
          console.warn(error);
        }
        cookies.remove('token', {path: '/'});
        navigate('/');
      }
    })()
  }, []);

  return (
    <div className='d-flex flex-nowrap'> 
      <Sidebar/>
      <div className='d-flex flex-column col-10'>
        <Navbar title={'INSTELLINGEN'}/>
        <ToastContainer/>
        <div className='d-flex'>
          {isLoading ? (
            <div className='mainBox'>
              <div className="d-flex px-3 py-2 mt-4 mx-4 col-10 justify-content-around flex-column position-absolute">
                <div className="spinner-border text-primary spinner-border"></div>
              </div>
            </div>
          ) : (
            <div className='mainBox'>
              <div className='d-flex px-3 py-2 mt-4 mx-4 col-10 justify-content-around flex-column position-absolute'>
                <div className='d-flex flex-row parentBoxSettings'>
                  <div className='whiteBox navigationBarSettings'>
                    <div className='ms-4 mt-3'>
                      <p className={`${search === 'punten' ? 'fw-bold' : ''} hover`} onClick={() => navigate('/instellingen?punten')}>Puntenwaarde</p>
                      <p className={`${search === 'bedrijf' ? 'fw-bold' : ''} hover`} onClick={() => navigate('/instellingen?bedrijf')}>Bedrijfsgegevens</p>
                    </div>
                  </div>
                  <div className='whiteBox settingsBox'>
                    {search === 'punten' && <Points settings={settings}/>}
                    {search === 'bedrijf' && <Company settings={settings}/>}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const Points = (props) => {
  const [settings, setSettings] = useState(props.settings);

  const handleSettings = (event) => {
    setSettings({...settings, [event.target.name]: event.target.value});
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (settings.id !== undefined) {
        await toast.promise(
          loyalty.put(`settings`, {
            id: settings.id,
            exchange_value: settings.exchange_value,
            points_value: settings.points_value,
            default_option: settings.default_option,
          }),
          {
            pending: 'Opslaan',
            success: 'Succesvol opgeslagen',
            error: 'Er is iets fout gegaan tijdens het opslaan'
          },
          {
            autoClose: 5000,
            style: {
              top: '100px'
            }
          }
        );
        window.location.reload();
        return;
      }

      await toast.promise(
        loyalty.post(`settings`, {
          exchange_value: settings.exchange_value,
          points_value: settings.points_value,
          default_option: settings.default_option ? settings.default_option : 0,
        }),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
          error: 'Er is iets fout gegaan tijdens het opslaan'
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );
      window.location.reload();
    } catch (error) {
      console.warn(error);
    }
  }

  const handleOptionChange = (event) => {
    if (event.target.value === '0') { // Points
      setSettings({...settings, default_option: 0});
    } else if (event.target.value === '1') { // Euros
      setSettings({...settings, default_option: 1});
    }
  };

  return (
    <form onSubmit={handleSubmit} className='ms-4 mt-3'>
      <h1>Puntenwaarde</h1>
      <span className='user-select-none'>* Hoeveel cent is één punt</span>
      <div className="input-group mb-3">
        <input value={settings.exchange_value ? settings.exchange_value : ''}  min="0" onChange={handleSettings} required name='exchange_value' type="number" className="inputField inputFieldsPoints"/>
        <div className='d-flex flex-column'>
          <a className="btn-primary arrowsInput arrowUp" onClick={() => setSettings({...settings, exchange_value: settings.exchange_value + 1})}><FontAwesomeIcon icon={faCaretUp} className='iconUp' /></a>
          <a className="btn-primary arrowsInput arrowDown" onClick={() => {
            if (settings.exchange_value > 0) {
              setSettings({...settings, exchange_value: settings.exchange_value - 1});
            }
            }}><FontAwesomeIcon icon={faCaretDown} className='iconDown' /></a>
        </div>
      </div>
      <span className='user-select-none'>* Ruilwaarde (hoeveel punten is één cent)</span>
      <div className="input-group mb-3">
        <input value={settings.points_value ? settings.points_value : ''}  min="0" onChange={handleSettings} required name='points_value' type="number" className="inputField inputFieldsPoints"/>
        <div className='d-flex flex-column'>
          <a className="btn-primary arrowsInput arrowUp" onClick={() => setSettings({...settings, points_value: settings.points_value + 1})}><FontAwesomeIcon icon={faCaretUp} className='iconUp' /></a>
          <a className="btn-primary arrowsInput arrowDown" onClick={() => {
            if (settings.points_value > 0) {
              setSettings({...settings, points_value: settings.points_value - 1});
            }
            }}>
              <FontAwesomeIcon icon={faCaretDown} className='iconDown' />
          </a>
        </div>
      </div>
      <span className='user-select-none'>Standaart selector</span>
      <div className="input-group mb-3">
        <div>
          <select
            id="reason-dropdown"
            value={settings.default_option ? settings.default_option : 0}
            onChange={handleOptionChange}
            className="inputField dropdownReason form-select heightInputs me-3 mb-3"
          >
            <option value="0">Punten</option>
            <option value="1">Euro's</option>
          </select>
        </div>
      </div>
      <p className='user-select-none'>* Verplicht veld</p>
      <button className="btn btn-primary me-4 ps-4 pe-4 buttonsAddCustomers" role="button">Opslaan</button>
    </form>
  );
}

const Company = (props) => {
  const [settings, setSettings] = useState(props.settings);
  const [favicon, setFavicon] = useState(null);

  const handleSettings = (event) => {
    setSettings({...settings, [event.target.name]: event.target.value});
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file === undefined) {
      return;
    }

    if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/gif') {
      event.target.value = null;
      ToastError('Je mag alleen een: png, jpeg of gif uploaden');
      return;
    }
    
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file.size > maxSize) {
      event.target.value = null;
      ToastError('Bestand mag niet groter zijn dan 5mb');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      setSettings({...settings, logo: base64String});
    };
    reader.readAsDataURL(file);
  };

  async function handleFaviconChange(event) {
    const file = event.target.files[0];
    if (!file) {
      setFavicon(null);
      return;
    }

    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      setFavicon(null);
      event.target.value = null;
      ToastError('Je mag alleen een png of jpeg uploaden');
      return;
    }

    const maxSize = 1 * 1024 * 1024; // 1MB in bytes

    if (file.size > maxSize) {
      setFavicon(null);
      event.target.value = null;
      ToastError('Bestand mag niet groter zijn dan 1mb');
      return;
    }

    const formData = new FormData();
    formData.append('favicon', file);
    setFavicon(formData);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (settings.id !== undefined) {
        await toast.promise(
          loyalty.put(`settings`, {
            id: settings.id,
            company_name: settings.company_name,
            contact_person: settings.contact_person,
            logo: settings.logo,
          }),
          {
            pending: 'Opslaan',
            success: 'Succesvol opgeslagen',
            error: 'Er is iets fout gegaan tijdens het opslaan'
          },
          {
            autoClose: 5000,
            style: {
              top: '100px'
            }
          }
        );
        
        await loyalty.post(`uploadFavicon`, favicon);
        window.location.reload();
        return;
      }

      ToastError(`Je moet eerst de punten waarde invullen`);
    } catch (error) {
      console.warn(error.response);
    }
  }

  return (
    <form onSubmit={handleSubmit} className='ms-4 mt-3'>
      <h1>Bedrijfsgegevens</h1>
      <span className='inputTitle text-black'>Bedrijfsnaam</span>
      <div className="input-group mb-3 mt-2 companyInfo">
        <input value={settings.company_name ? settings.company_name : ''} onChange={handleSettings} name='company_name' type="text" className="form-control rounded-pill"/>
      </div>
      <span className='inputTitle text-black'>Contactpersoon</span>
      <div className="input-group mb-3 mt-2 companyInfo">
        <input value={settings.contact_person ? settings.contact_person : ''} onChange={handleSettings} name='contact_person' type="text" className="form-control rounded-pill"/>
      </div>
      <span className='inputTitle text-black'>Logo</span>
      <div className="input-group mb-3 mt-2 companyInfo">
        <input onChange={handleFileChange} name='logo' accept=".png,.jpg,.gif" type="file" className="form-control rounded-pill"/>
      </div>
      <span className='inputTitle text-black'>
        Icon
        <span className='fw-normal'>
          (Als het pictogram niet verandert nadat u wijzigingen hebt aangebracht, moet u de pagina misschien een paar keer{' '}
          <a className='link link-primary text-decoration-underline hover' onClick={() => window.location.reload()}>verversen</a>
          {' '}om de wijzigingen te zien.)
        </span>
      </span>
      <div className="input-group mb-3 mt-2 companyInfo">
        <input onChange={handleFaviconChange} name='logo' accept=".png,.jpg" type="file" className="form-control rounded-pill"/>
      </div>
      <button className="btn btn-primary me-4 ps-4 pe-4 buttonsAddCustomers" role="button">Opslaan</button>
    </form>
  );
}

export default Settings;
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router';
import loyalty from '../Api/Loyalty';
import Sidebar from '../Navbars/Sidebar/Sidebar';
import Navbar from '../Navbars/Navbar/Navbar';
import "../table/Table.css"
import '../MainCss/Main.css';
import "./Points.css"
import { ToastContainer } from 'react-toastify';
import Modals from '../Modals/Modals';
import Searchfunction from '../Searchfunction';

const Points = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState(true);
  const [searchedBarcode, setSearchedBarcode] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const response = await loyalty.get(`settings`);
        if (response.data.length === 0) {
          setSettings(false);
        }
        setIsLoading(false);
      } catch (error) {
        console.warn(error);
        cookies.remove('token', { path: '/' });
        navigate('/');
      }
    })()
  }, []);

  const handleBarcodeChange = (barcode) => {
    setSearchedBarcode(barcode);
  }

  return (
    <div className='d-flex flex-nowrap'> 
      <Sidebar/>
      <div className='d-flex flex-column col-10'>
        <Navbar title={'PUNTENSALDO'}/>
        <ToastContainer/>
        <Modals barcode={searchedBarcode}/>

        <div className='d-flex'>
          {isLoading ? (
            <div className='mainBox'>
              <div className="d-flex px-3 py-2 mt-4 mx-4 col-10 justify-content-around flex-column position-absolute">
                <div className="spinner-border text-primary spinner-border"></div>
              </div>
            </div>
          ) : (
            <div className='mainBox'>
              <div className='d-flex px-4 py-2 mt-4 mx-5 col-10 justify-content-around flex-column position-absolute'>
                <h2 className='fw-bold mb-4'>Puntensaldo</h2>
                <div className='mb-4'>
                  <div className=' d-flex flex-column justify-content-between  w-75 mb-3'>
                    <div className='whiteBox heightCustomerSearchSmall ps-5 pe-4 py-4 mb-4'>
                      <h5 className='fw-bold'>Klant opzoeken</h5>
                      <div className='mb-3'>
                        <Searchfunction onBarcodeChange={handleBarcodeChange} select={true}/>
                      </div>
                    </div>
                    {!settings && (
                      <div className='whiteBox heightCustomerSearchSmall ps-5 pe-4 py-4 fitContendHeight'>
                        <h3>Voordat je dit systeem kan gebruiken moet je eerst de puntenwaarde instellen in de <a onClick={() => navigate(`/instellingen`)} className='link-primary hover'>instellingen</a></h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Points;
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../../Navbars/Sidebar/Sidebar';
import Navbar from '../../Navbars/Navbar/Navbar';
import loyalty from '../../Api/Loyalty';
import 'react-toastify/dist/ReactToastify.css';
import ToastError from '../../Toasts/ToastError';

const EditConsumer = () => {
  const navigate = useNavigate();
  const { barcode } = useParams();

  const [customer, setCustomer] = useState([]);

  const [email, setEmail] = useState('');

  useEffect(() => {
    (async () => {
      try {        
        const res = await loyalty.get(`customers?barcode=${barcode}`);

        if (res.data.length !== 1) {
          navigate(`/klanten`);
        }

        if (res.data[0].type === 1) {
          navigate(`/klanten/bedrijf/bewerken/${barcode}`);
        }

        const data = res.data[0];
        setCustomer(data);
        setEmail(data.email);
        setIsLoading(false);
      } catch (error) {
        console.warn(error);
        navigate(`/klanten`);
      }
    })()
  }, []);

  const [emailError, setEmailError] = useState(false);

  async function handleEmailChange(event) {
    setEmail(event.target.value);
    setEmailError(true);

    if (isValidEmail(event.target.value)) {
      try {
        const response = await loyalty.get(`customers?email=${event.target.value}`);
        if (response.data.length === 0 || event.target.value == customer.email) {
          setEmailError(false);
        } else {
          setEmailError(true);
          const data = response.data[0];
          ToastError(<span><a onClick={e => navigate(`/klanten/consument/${data.barcode}`)} className='link-primary hover'>{data.email}</a> bestaat al</span>)
        }
      } catch (error) {
        console.warn(error);
      }
    }
  }

  function isValidEmail(email) {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return emailRegex.test(email);
  }

  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (customer.barcode !== barcode) {
        navigate(`/klanten/consument${barcode}`)
      }

      if (emailError) {
        ToastError('Ongeldige email');
        return;
      }

      await loyalty.put(`customers`, {...customer, email: email});
      navigate(`/klanten/consument/${barcode}`);
    } catch (error) {
      console.warn(error);
      ToastError('Er is iets fout gegaan');
    }
  }

  const handleBlur = async (event) => {
    event.preventDefault();
    
    try {
      if (customer.barcode !== barcode) {
        navigate(`/klanten/consument${barcode}`)
      }

      if (emailError) {
        ToastError('Ongeldige email');
        return;
      }

      const response = await toast.promise(
        loyalty.put(`customers`, {
          ...customer,
          email: email
        }),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
          error: 'Er is iets fout gegaan tijdens het opslaan'
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );

      setCustomer(response.data);
    } catch (error) {
      console.warn(error);
    }
  }

  const handleChange = (e) => {
    setCustomer({...customer, [e.target.name]: e.target.value});
  }

  return (
    <div className='d-flex flex-nowrap'> 
      <Sidebar/>
      <div className='d-flex flex-column col-10'>
        <Navbar title={'KLANTEN'}/>
        <ToastContainer/>
        <div className='d-flex'>
          {isLoading ? (
            <div className='mainBox'>
              <div className="d-flex px-3 py-2 mt-4 mx-4 col-10 justify-content-around flex-column position-absolute">
                <div className="spinner-border text-primary spinner-border"></div>
              </div>
            </div>
          ) : (
            <div className='mainBox'>
              <div className='d-flex px-4 py-2 mt-4 mx-5 col-10 justify-content-around flex-column position-absolute'>
                <div className='mb-4'>
                  <div className=' d-flex flex-row justify-content-between makeCustomer'>
                    <div className='whiteBox boxNieuwClient p-4 mb-5 me-5'>
                      <h3 className='fw-bold ps-3 mb-4'>Klant bewerken</h3>
                      <form onSubmit={handleSubmit} className='ps-3'>
                        <p className='inputTitle mb-0'>Kaartnummer</p>
                        <input defaultValue={customer.barcode} required name="barcode" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' disabled type="number"></input>
                        <p className='inputTitle mb-0'>Voornaam</p>
                        <input value={customer.first_name ? customer.first_name : ''} onChange={handleChange} onBlur={handleBlur} name="first_name" autoComplete="given-name" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="text"></input>
                        <p className='inputTitle mb-0'>Achternaam</p>
                        <input value={customer.last_name ? customer.last_name : ''} onChange={handleChange} onBlur={handleBlur} name="last_name" autoComplete="family-name" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="text"></input>
                        <p className='inputTitle mb-0'>E-mailadres</p>
                        <input value={email} onChange={handleEmailChange} onBlur={handleBlur} required name="email" autoComplete="email" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="email"></input>
                        <p className='inputTitle mb-0'>Telefoonnummer</p>
                        <input value={customer.tel_number ? customer.tel_number : ''} onChange={handleChange} onBlur={handleBlur} name="tel_number" autoComplete="tel" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="tel"></input>
                        <p className='inputTitle mb-0'>Geboortedatum</p>
                        <input value={customer.date_of_birth ? customer.date_of_birth : ''} onChange={handleChange} onBlur={handleBlur} name="date_of_birth" className='mt-2 form-control inputField inputFieldWidth heightInputs pe-2 mb-3' type="date"></input>
                        <p className='inputTitle mb-0'>Postcode</p>
                        <input value={customer.address_postalcode ? customer.address_postalcode : ''} onChange={handleChange} onBlur={handleBlur} name="address_postalcode" autoComplete="postal-code" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="text"></input>
                        <div className='d-flex flex-row col-12 streetHouseNum'>
                          <div className='col-5 me-4'>
                            <p className='inputTitle mb-0'>Straat</p>
                            <input value={customer.address_streetname ? customer.address_streetname : ''} onChange={handleChange} onBlur={handleBlur} name="address_streetname" className='mt-2 inputField heightInputs widthStreetInputs col-12 mb-3 me-3' type="text"></input>
                          </div>
                          <div className='col-3'>
                            <p className='inputTitle mb-0'>Huisnummer</p>
                            <input value={customer.address_house_number ? customer.address_house_number : ''} onChange={handleChange} onBlur={handleBlur} name="address_house_number" className='mt-2 col-12 inputField inputFieldWidth widthStreetInputs heightInputs mb-3' type="number"></input>
                          </div>
                          <div className='col-3'>
                            <p className='inputTitle mb-0'>Toevoeging</p>
                            <input value={customer.address_addition ? customer.address_addition : ''} onChange={handleChange} onBlur={handleBlur} name="address_addition" className='mt-2 col-12 inputField inputFieldWidth widthStreetInputs heightInputs mb-3' type="text"></input>
                          </div>
                        </div>
                        <p className='inputTitle mb-0'>Stad</p>
                        <input value={customer.address_city ? customer.address_city : ''} onChange={handleChange} onBlur={handleBlur} name="address_city" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="text"></input>
                        <p className='inputTitle mb-0'>Land</p>
                        <input value={customer.address_country ? customer.address_country : ''} onChange={handleChange} onBlur={handleBlur} name="address_country" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="text"></input>
                        <button type='submit' className="btn addClientButton hover text-nowrap"  role="button">Consument bewerken</button>
                      </form>
                    </div>
                    <div className='whiteBox heightCustomerNote newClientNote mt-0 py-4 pe-4 ps-5'>
                      <h5 className='fw-bold'>Klantnotitie</h5>
                      <textarea value={customer.note ? customer.note : ''} onChange={handleChange} onBlur={handleBlur} name='note' rows="12" cols="20" className='textareaStyle'></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditConsumer;

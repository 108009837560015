import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import './sidebar.css';
import Dashboard from '../../Icons/Dashboard.png';
import puntensaldo from '../../Icons/puntensaldo.png';
import Klanten from '../../Icons/Klanten.png';
import Instellingen from '../../Icons/Instellingen.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import loyalty from '../../Api/Loyalty';
import defaultLogo from './logoTechDogDark.png';

const Sidebar = () => {
  const [logo, setLogo] = useState(undefined);

  useEffect(() => {
    (async () => {
      try {
        const response = await loyalty.get(`logo`);
        setLogo(response.data.logo);
      } catch (error) {
        if (error.response.status === 404) {
          setLogo(null);
        } else {
          console.warn(error);
        }
      }
    })()
  }, []);

  const SidebarLinks = [
    { to: '/puntensaldo', text: 'Puntensaldo', icon: <img src={puntensaldo} alt="Puntensaldo" className='iconsSidebar text-white' /> },
    { to: '/klanten', text: 'Klanten', icon: <img src={Klanten} alt="Klanten" className='iconsSidebar text-white' /> },
    { to: '/instellingen?punten', text: 'Instellingen', icon: <img src={Instellingen} alt="Instellingen" className='iconsSidebar text-white' /> },
  ];

  const SidebarLink = ({ to, text, icon}) => (
    <div className='my-2 icon-container'>
      <Link className='text-decoration-none d-flex flex-nowrap ms-3' to={to}><p className='text-white me-3 mb-1 mt-1'>{icon}</p><p className='icon-text'>{text}</p></Link>
    </div>
  );

  return (
    <div className='widthSidebar'>
      <nav className='sidebar'>
        <div className='p-3 mb-5 d-flex justify-content-center imgBox'>
          {logo !== null ? (
            <img className='img-fluid siteLogo' src={logo  && `data:image/png;base64,${logo}`}/>
          ) : (
            <img className='img-fluid siteLogo' src={defaultLogo} alt='Logo'></img>
          )}
        </div>
        <div>
          {SidebarLinks.map((link, index) => (
            <SidebarLink key={index} to={link.to} text={link.text} icon={link.icon}/>
          ))}
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
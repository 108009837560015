import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import './Navbar.css';
import Zoekicon from '../../Icons/Zoekicon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faChevronDown, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import loyalty from '../../Api/Loyalty';

const Navbar = (props) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dropdownContentRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState('auto');
  const lineRef = useRef(null);


  const [user, setUser] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false); 

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await loyalty.get(`auth?ApiKey=${cookies.get('token')}`);
        
        if (response.data.account_status === 1) {
          cookies.remove('token', { path: '/' });
          navigate('/');
        }

        setUser(response.data);
      } catch (error) {
        console.warn(error);
        if (error.response.status === 401) {
          cookies.remove('token', { path: '/' });
          navigate('/');
        }
      }
    })()
  }, []);

  const logout = () => {
    cookies.remove('token', {path: '/'});
    navigate('/');
  }

  useEffect(() => {
    if (dropdownContentRef.current) {
      const container = dropdownContentRef.current.previousSibling;
      setContainerWidth(`${container.offsetWidth}px`);
    }
  }, [showDropdown]);

  useEffect(() => {
    if (lineRef.current) {
      lineRef.current.style.width = containerWidth;
    }
  }, [containerWidth]);


  return (
    <nav className='navbar pt-3 pb-3 pe-5 ps-4 bg-white col-10 d-flex flex-row justify-content-between'>
      <div className=' d-flex flex-row justify-content-center'>
        <h1 className='pt-3 pageTitle'>{props.title !== null ? props.title.toUpperCase() : ""}</h1>
      </div>
      <div className='iconHeightNav d-flex pt-3'>
        <div className={`dropdownNav ps-4 ${showDropdown ? 'clicked' : ''}`}>
          <div className='userInfoMenu hover d-flex flex-row' onClick={toggleDropdown}>
            <div className="profile-container d-flex align-items-center">
              <img className='img-fluid profilePicture rounded-circle' src={user.picture && `data:image/png;base64,${user.picture}`} />
              <p className='fw-bold mb-4'>
                {`Hi, ${user.first_name ? (user.first_name + ' ' + (user.last_name ? user.last_name : '')) : ('Werknemer')}`}
              </p>
            </div>
            <FontAwesomeIcon className={`px-1 dropdownIndicator ${showDropdown ? 'rotate' : ''}`} icon={faChevronDown} />
          </div>
          <div className="hover-area" onClick={toggleDropdown}></div>
          <div className="dropdown-content" style={{ display: showDropdown ? 'flex' : 'none', width: containerWidth }} ref={dropdownContentRef}>
            <div className=' d-flex flex-column dropdownListedItems'>
              <a className=' fw-semibold links mb-2 hover text-black' onClick={() => navigate(`/instellingen/profiel`)}>Profiel</a>
              <a className=' fw-semibold links mb-2 hover text-black' onClick={() => navigate(`/instellingen/email`)}>E-mailadres wijzigen</a>
              <a className=' fw-semibold links mb-2 hover text-black' onClick={() => navigate(`/instellingen/wachtwoord`)}>Wachtwoord wijzigen</a>
            </div>
            <a href="#" className='link mt-5 pt-3'></a>
            <p className='line' ref={lineRef}></p>
            <a className='logOut' onClick={logout}><FontAwesomeIcon icon={faArrowRightFromBracket} />  Log out</a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
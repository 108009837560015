import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { ToastContainer } from 'react-toastify';
import "./Admin.css"
import defaultLogo from './logoTechDogDark.png';
import ToastError from '../Toasts/ToastError';
import adminLoyalty from '../Api/AdminLoyalty';

const AdminLogin = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [password, setPassword] = useState();
  const [logo, setLogo] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const expirationDate = new Date();
  expirationDate.setHours(23, 59, 59, 0); // Set the time to 23:59:59.000

  useEffect(() => {
    (async () => {
      try {
        const response = await adminLoyalty.get(`logo`);
        setLogo(response.data.logo);
      } catch (error) {
        if (error.response.status === 404) {
          setLogo(null);
        } else {
          console.warn(error);
        }
      }
    })()
    setTimeout(() => {
      setIsLoading(false);
    }, 150); // Time for the image to render
  }, []);

  useEffect(() => {
    (async () => {
      const key = cookies.get('adminToken');
      if (key) {
        try {
          await adminLoyalty.get(`authAdmin?ApiKey=${key}`);
          navigate('/admin/dashboard');
        } catch (error) {
          if (error.response.status !== 401) {
            console.warn(error.response.data);
          }
          cookies.remove('adminToken', { path: '/admin'});
        }
      }
    })()
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await adminLoyalty.get(`authAdmin?password=${password}`);
      cookies.set('adminToken', response.data.api_token, { path: '/admin', expires: expirationDate });
      navigate('/admin/dashboard');
    } catch (error) {
      console.error(error);
      ToastError(error.response.data.error);
    }
  }

  return (
    <div className='backgroundColor'>
      <div className='d-flex justify-content-center formContainer'>
        <ToastContainer/>
        {!isLoading ? (
          <form onSubmit={handleLogin}>
            <div className='mb-5 d-flex justify-content-center'>
              {logo !== null ? (
                <img className='img-fluid logoAdmin' src={logo && `data:image/png;base64,${logo}`}/>
              ) : (
                <img className='img-fluid logoAdmin' src={defaultLogo} alt='Logo'></img>
              )}
            </div>
            <div className="mb-3 input-group-sm d-flex justify-content-center">
              <input type="password" required onChange={e => setPassword(e.target.value)} className="inputField inputStyle" placeholder="Wachtwoord"/>
            </div>
            <div className='mb-3 input-group-sm'>
              <button type="submit" className="btn w-100 rounded-pill inputStyleAdmin btnAdmin">Login</button>
            </div>
          </form>
        ) : (
          <div className='mb-5 d-flex justify-content-center'>
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary spinner-border-sm">
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminLogin;

import React, { useMemo, useState, useEffect } from "react";
import Sidebar from '../Navbars/Sidebar/Sidebar';
import Navbar from '../Navbars/Navbar/Navbar';
import { useTable } from "react-table";
import loyalty from "../Api/Loyalty";
import { useNavigate } from 'react-router';
import TablePreset from "../table/TablePreset";
import "../table/Table.css";
import "../MainCss/Main.css";
import "./Customers.css" 
import Zoekicon from "../Icons/Zoekicon.png"

function Customers() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [searchTerm, setSearchTerm] = useState('');

  const fetchAll = async () => {
    setLoading(true);
    const [customersResponse] = await Promise.all([
      loyalty.get(`customers?page=${pagination.page}&page_size=${pagination.page_size}${searchTerm ? `&searchTerm=${searchTerm}` : ''}${searchTerm ? `&noSearch=${[
        'id',
        'type',
        'status',
        'created_at',
        'updated_at',
        'newsletter_subscription',
        'mail_subscription',
      ]}` : ''}`),
    ]);

    setData(customersResponse.data.data);
    setPagination({...pagination, "max_items": customersResponse.data.total, "begin_of_page": customersResponse.data.from, "end_of_page": customersResponse.data.to})
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (searchTerm === '') {
      fetchAll();
      return;
    }
    const waitFetch = setTimeout(() => {
      try {
        fetchAll();
      } catch (error) {
        console.warn(error)
      }
    }, 750);

    return () => clearTimeout(waitFetch);
  }, [searchTerm]);

  useEffect(() => {
    try {
      fetchAll();
    } catch (error) {
      console.warn(error)
    }
  }, [pagination.page_size, pagination.page]);

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    setSelectedIds(previousIds => {
      if (isSelected) {
        return [...previousIds, productId];
      } else {
        return previousIds.filter(id => id !== productId);
      }
    });
  };

  

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            <label className="checkbox-container">
              <input
                type="checkbox"
                onChange={e => {
                  setSelectedIds(e.target.checked ? data.map(data => data.id) : []);
                }}
              />
              <span className="checkmark"></span>
            </label>
          </>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          const isSelected = selectedIds.includes(row.original.id);
          return (
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={e => handleSelection(row.original.id, e.target.checked)}
              />
              <span className="checkmark"></span>
            </label>
          );
        }
      },
      {
        Header: "Naam",
        Cell: ({ row }) => {
          if (!row.original.first_name && !row.original.last_name) {
            return <a onClick={() => navigate(`/klanten/${row.original.type === 0 ? 'consument' : 'bedrijf'}/${row.original.barcode}`)} className='link-primary hover'>Anoniem</a>;
          }
          return <a onClick={() => navigate(`/klanten/${row.original.type === 0 ? 'consument' : 'bedrijf'}/${row.original.barcode}`)} className='link-primary hover'>{row.original.first_name ? row.original.first_name : ''} {row.original.last_name ? row.original.last_name : ''}</a>;
        }
      },
      {
        Header: "E-mailadres",
        Cell: ({ row }) => {
          return row.original.email;
        }
      },
      {
        Header: "Puntensaldo",
        accessor: "balance",
      },
      {
        Header: "Klantsoort",
        Cell: ({ row }) => {
          return row.original.type === 0 ? 'Consument' : 'Bedrijf';
        }
      },
    ],
    [selectedIds, setSelectedIds, data]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: data });
  
  const Action = (methode) => {
    if (methode == "suspend") {
      setTimeout(() => {
        suspend();
      }, 500);
    } else if (methode == 'Select All') {
      setSelectedIds(data.map(e => e.id));
    } else if (methode == 'De-select All') {
      setSelectedIds([]);
    }
  }

  const suspend = async () => {
    const result = window.confirm("Weet u zeker dat u al deze geselecteerde klanten wil verwijderen? Deze functie kan NIET ongedaan worden");
    if (!result) {
      return;
    }

    await loyalty.delete(`customers?ids=${selectedIds}`);
    setSelectedIds([]);
    fetchAll();
  }

  return (
    <div className='d-flex flex-nowrap'>
      <Sidebar shortcuts={[{'to': '/table', 'text': 'Tabel testen'}]}/>
      <div className='d-flex flex-column col-10' style={{backgroundColor: "#f4f4f4"}}>
        <Navbar title={'KLANTEN'}/>
          <div className='mainBox d-flex justify-content-center '>
            <div className="mb-5 position-absolute p-5 w-100 parentBox">
              <div className="mb-3 d-flex flex-row justify-content-between navigationItemsTable">
                <div className="d-flex flex-row actionSearchParent">
                  <select className="form-select actionDropdown fw-semibold me-3 border-0" value={'Actie'} style={{width: "100px", paddingLeft: "20px"}} onChange={e => Action(e.target.value)}>
                    <option hidden>Actie</option>
                    <option className="optionsSelect">Select All</option>
                    <option value={'suspend'} disabled={selectedIds.length !== 0 ? false : true} className="optionsSelect">
                      Verwijder {selectedIds.length} {selectedIds.length === 1 ? "klant" : "klanten"}
                    </option>
                    <option disabled={selectedIds.length === 0} className="optionsSelect">De-select All</option>
                  </select>
                  <div className=" d-flex flex-nowrap">
                    <input
                      value={searchTerm}
                      onChange={event => setSearchTerm(event.target.value)}
                      className='inputField SearchFieldWidth'
                      type="text"
                      placeholder='Zoeken...'
                    />
                    <img src={Zoekicon} alt="Zoekicon" className='iconSearch text-secondary me-1 iconLineHeight mt-2' />
                  </div>
                </div>
                <div className="addCustomerParent">
                  <a className="btn btn-primary buttonsAddCustomers me-4" onClick={() => navigate(`/klanten/consument/nieuw`)} role="button">Consument aanmaken</a>
                  <a className="btn btn-primary buttonsAddCustomers" onClick={() => navigate(`/klanten/bedrijf/nieuw`)} role="button">Bedrijf aanmaken</a>
                </div>
              </div>
              <div className="customerTable p-4 px-0 pt-0 mb-5">
                {!loading ?
                  <TablePreset data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination}]} setPagination={setPagination} handleSelection={handleSelection} primary_key={'id'}/>
                : <div className="table-container"><table><thead><tr><td><div>Loading</div></td></tr></thead></table></div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Customers from "./components/Customers/Customers";
import EditCustomer from "./components/Customers/Consumers/EditConsumer";
import NewCustomer from "./components/Customers/Consumers/NewConsumer";
import VieuwCustomer from "./components/Customers/Consumers/VieuwConsumer";
import Login from "./components/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import Points from "./components/Points/Points";
import NewBusines from "./components/Customers/Businesses/NewBusines";
import VieuwBusines from "./components/Customers/Businesses/VieuwBusines";
import Settings from "./components/Settings/Settings";
import Profile from "./components/Settings/Profile";
import EditBusines from "./components/Customers/Businesses/EditBusines";
import AdminDashBoard from "./components/Admin/AdminDashboard";
import AdminLogin from "./components/Admin/AdminLogin";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/puntensaldo" element={<Points />}></Route>
        <Route path="/klanten" element={<Customers />}></Route>
        <Route path="/klanten/consument/nieuw" element={<NewCustomer />}></Route>
        <Route path="/klanten/consument/:barcode" element={<VieuwCustomer />}></Route>
        <Route path="/klanten/consument/bewerken/:barcode" element={<EditCustomer />}></Route>
        <Route path="/klanten/bedrijf/nieuw" element={<NewBusines />}></Route>
        <Route path="/klanten/bedrijf/:barcode" element={<VieuwBusines />}></Route>
        <Route path="/klanten/bedrijf/bewerken/:barcode" element={<EditBusines />}></Route>
        <Route path="/instellingen" element={<Settings />}></Route>
        <Route path="/instellingen/:setting" element={<Profile />}></Route>
        <Route path="/admin" element={<AdminLogin />}></Route>
        <Route path="/admin/dashboard" element={<AdminDashBoard />}></Route>
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router';
import { Modal } from 'bootstrap';
import loyalty from '../Api/Loyalty';
import Sidebar from '../Navbars/Sidebar/Sidebar';
import Navbar from '../Navbars/Navbar/Navbar';
import '../MainCss/Main.css';
import './Modals.css'
import Zoekicon from './../Icons/Zoekicon.png';
import Pen from './../Icons/Pen.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import ToastError from '../Toasts/ToastError';
import ToastSuccess from '../Toasts/ToastSuccess';
import Searchfunction from '../Searchfunction';

const Modals = (props) => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState([]);

  const openModal2 = () => {
    const modal1 = Modal.getInstance(document.getElementById('Modal'));
    if (modal1) {
      modal1.hide();
    }
    
    const modal2 = new Modal(document.getElementById('Modal2'));
    modal2.show();
  };
  

  const closeModal = (url) => {
    const modalElement1 = document.getElementById('Modal');
    const modalElement2 = document.getElementById('Modal2');
  
    if (modalElement1) {
      const modal1 = Modal.getInstance(modalElement1);
      if (modal1) {
        Modal.getInstance(modalElement1).hide();
      }
    }
  
    if (modalElement2) {
      const modal2 = Modal.getInstance(modalElement2);
      if (modal2) {
        modal2.hide();
      }
    }
    
    setTimeout(() => {
      if (url !== undefined) {
        navigate(url);
      }
    }, 50); // timeout needed otherwise it does not close correctly.
  };

  const getCustomer = (customer) => {
    setCustomer([customer]);
  }

  const linkCustomer = async () => {
    try {
      const response = await toast.promise(
        loyalty.put(`customers`, {
          id: customer[0].id,
          barcode: props.barcode,
        }),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
          error: 'Er is iets fout gegaan tijdens het opslaan'
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );
      
      setCustomer([response.data]);
      closeModal(`/klanten/${response.data.type === 0 ? 'consument' : 'bedrijf'}/${props.barcode}`);
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <div>
      <div className="modal" id="Modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-end pb-0">
              <a className="btn" role="button" data-bs-dismiss="modal"><FontAwesomeIcon icon={faXmark} className="closeModal"/></a>
            </div>
            <div className="modal-body text-center pt-0">
              <h4 className='mb-4'>Geen klant gevonden</h4>
              <div className='buttonsModal'>
                <button onClick={() => openModal2()} className="btn btn-primary rounded-pill mb-3 col-9 text-nowrap buttonColor">Koppelen aan bestaande klant</button>
                <div className='mb-5 d-flex justify-content-center'>
                  <button onClick={() => closeModal(`/klanten/consument/nieuw?barcode=${props.barcode}`)} className='btn btn-primary rounded-pill col-4 me-5 text-nowrap buttonColor'>Nieuw consument</button>
                  <button onClick={() => closeModal(`/klanten/bedrijf/nieuw?barcode=${props.barcode}`)} className='btn btn-primary rounded-pill col-4 text-nowrap buttonColor'>Nieuw bedrijf</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="Modal2">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-end pb-0">
              <a onClick={() => setCustomer({data: []})} className="btn" role="button" data-bs-dismiss="modal"><FontAwesomeIcon icon={faXmark} className="closeModal"/></a>
            </div>
            <div className="modal-body text-center pt-0">
              <h4 className='mb-4'>Koppelen aan bestaande klant</h4>
              <div className='mb-3 ms-4'>
                <Searchfunction getCustomer={getCustomer} select={true}/>
              </div>
              {customer.length > 0 ? (
                <div className='d-flex flex-column justify-content-start '>
                  <div className=' text-start ms-5'>
                    <div className='d-flex flex-row justify-content-between'>
                      <p className='fw-bold'>Naam</p>
                      <p className='text-start textPlace fw-semibold'>{`${customer[0].first_name ?? ''} ${customer[0].last_name ?? ''}`}</p>
                    </div>
                    <div className='d-flex flex-row justify-content-between '>
                      <p className='fw-bold'>Adres</p>
                      <div className='textPlace'>
                        <p className='text-start mb-0 fw-semibold'>{`${customer[0].address_streetname ?? ''} ${customer[0].address_house_number ?? ''} ${customer[0].address_addition ?? ''}`}</p>
                        <p className='text-start mb-0 fw-semibold'>{`${customer[0].address_postalcode ? `${customer[0].address_postalcode},` : ''} ${customer[0].address_city ?? ''}`}</p>
                        <p className='text-start fw-semibold'>{`${customer[0].address_country ?? ''}`}</p>
                      </div>
                    </div>
                    <div className='d-flex flex-row justify-content-between '>
                      <p className='fw-bold'>E-mailadres</p>
                      <p className='text-start textPlace fw-semibold'>{`${customer[0].email ?? ''}`}</p>
                    </div>
                    <div className='d-flex flex-row justify-content-between '>
                      <p className='fw-bold'>Telefoonnummer: </p>
                      <p className='text-start textPlace fw-semibold'> {`${customer[0].tel_number ?? ''}`}</p>
                    </div>
                    <div className='d-flex flex-row justify-content-between '>
                      <p className='fw-bold'>Geboortedatum: </p>
                      <p className='text-start textPlace fw-semibold'> {`${customer[0].date_of_birth ?? ''}`} {`${customer[0].date_of_birth ? `(${getAge(customer[0].date_of_birth)})` : ''}`}</p>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-between '>
                    <div>
                        <button onClick={() => closeModal()} className="btn rounded-pill mb-3 bg-danger text-nowrap buttonColor connectPas">Huidige kaart gebruiken: {customer[0].barcode}</button>
                    </div>
                    <div>
                      <button onClick={linkCustomer} className="btn rounded-pill mb-3 bg-warning text-nowrap buttonColor connectPas">Nieuwe kaart gebruiken: {props.barcode}</button>
                    </div>
                  </div>
                </div>
              ) : ('')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const getAge = (date) => {
  const today = new Date();

  const birthdayString = `${date}`;
  const birthday = new Date(birthdayString);

  let age = today.getFullYear() - birthday.getFullYear();

  if (today.getMonth() < birthday.getMonth() || (today.getMonth() == birthday.getMonth() && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
}

export default Modals;
import React, { useEffect, useState, useMemo, useRef } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate, useParams } from 'react-router';
import { useTable } from "react-table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loyalty from '../../Api/Loyalty';
import Sidebar from '../../Navbars/Sidebar/Sidebar';
import Navbar from '../../Navbars/Navbar/Navbar';
import Zoekicon from './../../Icons/Zoekicon.png';
import ArrowDown from './../../Icons/ArrowDown.png';
import Pen from './../../Icons/Pen.png';
import Cake from './../../Icons/Cake.png';
import TablePreset from "../../table/TablePreset";
import "../../table/Table.css"
import "./Busines.css"
import '../../MainCss/Main.css';
import Modals from '../../Modals/Modals';
import ToastError from '../../Toasts/ToastError';
import Searchfunction from '../../Searchfunction';

const VieuwBusines = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { barcode } = useParams();

  const [values, setValues] = useState({
    amount: '',
    reason: 'Aankoop',
    note: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [searchedBarcode, setSearchedBarcode] = useState('');
  const dateOptions = {
    timeZone: "Europe/Amsterdam",
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const [customer, setCustomer] = useState([]);
  const [settings, setSettings] = useState([]);
  const [selectedOptionType, setSelectedOptionType] = useState('0');
  const [selectedOption, setSelectedOption] = useState("Aankoop");
  const [inputReason, setInputReason] = useState("");

  const fetchAll = async () => {
    setIsLoading(true);
    try {
      const customer = await loyalty.get(`customers?barcode=${barcode}`);

      if (customer.data.length === 0) {
        navigate(`/klanten`);
        return;
      } else if (customer.data[0].type === 0) {
        navigate(`/klanten/consument/${barcode}`);
      }

      const settings = await loyalty.get(`settings`);
      if (settings.data.length === 0) {
        ToastError(`U moet eerst een instelling instellen and kunt u deze pagina niet bezoeken`);
        navigate(`/instellingen`);
        return;
      }
      setSettings(settings.data[0]);
      setSelectedOptionType(settings.data[0].default_option.toString());

      setCustomer(customer.data[0]);
      values.note = customer.data[0].note ? customer.data[0].note : '';

      setIsLoading(false);
    } catch (error) {
      if (error.response.status !== 401) {
        console.warn(error);
      }
      cookies.remove('token', { path: '/'});
      navigate(`/klanten`);
    }
  }

  useEffect(() => {
    fetchAll();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const button = event.nativeEvent.submitter.name;
    const number = Number(values.amount.replace('€ ', '').replace(',', '.'))

    if (number <= 0) {
      ToastError('Nummer moet groter zijn dan 0');
      return;
    }

    if (selectedOptionType === '0' && number % 1 != 0) {
      ToastError('Nummer moet een heel getal zijn');
      return;
    }

    if (selectedOptionType === '1' && number < settings.exchange_value / 100) {
      ToastError(`De euro's zijn minder dan de ingestelde centen voor het minimum van 1 punt`);
      return;
    }

    let amount = 0;
    if (button === 'add') {
      amount = number * 1;
    } else if (button === 'remove') {
      amount = number * -1;
    }

    try {
      await toast.promise(
        loyalty.post(`transactions`, {
          customer_id: customer.id,
          amount: amount,
          reason: values.reason,
          type: selectedOptionType,
        }),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
          error: 'Er is iets fout gegaan tijdens het opslaan'
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );

      setValues({...values, amount: '', reason: 'Aankoop'});
      fetchAll();
    } catch (error) {
      console.warn(error);
    }
  }

  const handleBarcodeChange = (barcode) => {
    setSearchedBarcode(barcode);
  }

  const handleNote = async (event) => {
    try {
      await toast.promise(
        loyalty.put(`customers`, {
          id: customer.id,
          note: event.target.value,
        }),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
          error: 'Er is iets fout gegaan tijdens het opslaan'
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );
    } catch (error) {
      console.warn(error);
    }
  }

  const handleOptionChange = (event) => {
    if (event.target.value === 'Aankoop') {
      values.reason = 'Aankoop';
    } else {
      values.reason = inputReason;
    }
    setSelectedOption(event.target.value);
  };

  const handleOptionChangeTypeSaldo = (event) => {
    setValues({...values, amount: ''});
    setSelectedOptionType(event.target.value);
  };

  const handleReasonChange = (event) => {
    values.reason = event.target.value;
    setInputReason(event.target.value);
  };

  const handleAmountBlur = (event) => {
    if (selectedOptionType === '1' && event.target.value) {
      const number = Number(values.amount.replace('€ ', '').replace(',', '.')).toFixed(2);
      if (number === "NaN") {
        setValues({...values, amount: ''})
        ToastError(`Voer een geldig nummer in`);
        return;
      }

      const amount = number.toString().replace('.', ',');
      setValues({...values, amount: `€ ${amount}`});
    }
  }

  const handleValues = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
  }

  return (
    <div className='d-flex flex-nowrap'> 
      <Sidebar/>
      <div className='d-flex flex-column col-10'>
        <Navbar title={'PUNTENSALDO'}/>
        <ToastContainer/>
        <Modals barcode={searchedBarcode}/>

        <div className='d-flex'>
          {isLoading ? (
            <div className='mainBox'>
              <div className="d-flex px-3 py-2 mt-4 mx-4 col-10 justify-content-around flex-column position-absolute">
                <div className="spinner-border text-primary spinner-border"></div>
              </div>
            </div>
          ) : (
            <div className='mainBox'>
              <div className='d-flex px-4 py-2 mt-4 mx-5 col-10 justify-content-around flex-column position-absolute'>
                <div className='mb-4'>
                  <div className=' flex-row justify-content-between searchConsumer mb-3 d-flex'>
                    <div className='whiteBox heightCustomerSearchSmall ps-5 pe-4 py-4'> 
                      <h5 className='fw-bold'>Nieuwe klant opzoeken</h5>
                      <div className='mb-3'>
                        <Searchfunction onBarcodeChange={handleBarcodeChange}/>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-row justify-content-between col-12 parentBoxes mb-3 customerSearchInfo'>
                    <div className='whiteBox heightCustomerSearchBig ps-5 pe-4 py-4'>
                      <div className='mb-3 d-flex flex-row'>
                        <div className=' me-4'>
                          <p className='fw-500 inputTitle text-black'>Huidige punten saldo</p>
                          <input 
                            className='inputField balance fw-semibold text-center saldoShowers'
                            disabled
                            placeholder={customer.balance ? customer.balance : 0}
                          />
                        </div>
                        <div>
                          <p className='fw-500 inputTitle text-black'>Saldo in euro's</p>
                          <input
                            className='inputField balance fw-semibold text-center saldoShowers'
                            disabled
                            placeholder={`€ ${customer.balance ? (Math.floor(customer.balance / settings.points_value) / 100).toFixed(2).toString().replace('.', ',') : 0}`}
                          />
                        </div>
                      </div>
                      <div className='mb-3'>
                        <div>
                          <p className='fw-500 inputTitle text-black'>Type saldo bijboeken</p>
                          <div className='d-flex flex-nowrap'>
                            <select
                              id="reason-dropdown"
                              value={selectedOptionType}
                              onChange={handleOptionChangeTypeSaldo}
                              className="inputField dropdownReason form-select heightInputs me-3 mb-3"
                            >
                              <option value="0">Punten</option>
                              <option value="1">Euro's</option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <form onSubmit={handleSubmit} autoComplete='off'>
                            <div className='d-flex flex-row mb-4 flex-wrap'>
                              <div>
                                <p className='fw-500 inputTitle text-black'>{selectedOptionType === '0' ? 'Vul het aantal punten in' : 'Vul het bedrag in'}</p>
                                <input type='text' value={values.amount} onChange={handleValues} onBlur={handleAmountBlur} name='amount' className='inputField text-center inputAddRemove heightInputs me-3'/>
                              </div>
                              <div>
                                <p className='fw-500 inputTitle text-black'>Reden</p>
                                <select
                                  id="reason-dropdown"
                                  value={selectedOption}
                                  onChange={handleOptionChange}
                                  className="inputField form-select dropdownReason heightInputs me-3 "
                                >
                                  <option value="Aankoop">Aankoop</option>
                                  <option value="Overig">Overig</option>
                                </select>
                              </div>
                              <div>
                                {selectedOption === "Overig" && (
                                  <div className='d-flex flex-column'>
                                    <label className='inputTitle text-black'>Reden</label>
                                    <input
                                      type="text"
                                      id="reason-input"
                                      name="reason-input"
                                      value={inputReason}
                                      required
                                      onChange={handleReasonChange}
                                      className="inputField inputAddRemove heightInputs me-3"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <button type='submit' name='add' className="btn addBalance me-3 fitContendWidth" role="button">Bijboeken</button>
                              <button type='submit' name='remove' className="btn removeBalance fitContendWidth" role="button">Afboeken</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='whiteBox heightCustomerInfo py-4 pe-4 ps-5'>
                      <div className='d-flex flex-row justify-content-between mb-4'>
                        <h5 className='fw-bold'>Klantgegevens</h5>
                        <img onClick={() => navigate(`/klanten/bedrijf/bewerken/${barcode}`)} src={Pen} alt="Pen" className='iconsNav hover'/>
                      </div>
                      <div>
                        <div className=' d-flex flex-row'>
                          <p className=' fw-semibold me-1 mb-0 text-nowrap'>Bedrijf naam:</p>
                          <p className=' mb-0 textBold'>{`${customer.company_name ? customer.company_name : ''}`}</p>
                        </div>

                        <div className=' d-flex flex-row'>
                          <p className=' fw-semibold me-1 mb-0'>Naam:</p>
                          <p className='text-info mb-0 textBold'>{`${customer.first_name ? customer.first_name : ''} ${customer.last_name ? customer.last_name : ''}`}</p>
                        </div>

                        <div className='d-flex flex-row'>
                          <p className='fw-semibold me-1 mb-0'>Barcode:</p>
                          <p className='textBold mb-0'>{barcode}</p>
                        </div>

                        <div className='d-flex flex-row'>
                          <p className='fw-semibold me-1 mb-0'>KVK nummer:</p>
                          <p className='textBold mb-0'>{`${customer.cocnumber ? customer.cocnumber : ''}`}</p>
                        </div>

                        <div className='d-flex flex-row'>
                          <p className='fw-semibold me-1 mb-3'>BTW nummer:</p>
                          <p className='textBold'>{`${customer.vatnumber ? customer.vatnumber : ''}`}</p>
                        </div>

                        <div className='d-flex flex-row'>
                          <p className='fw-semibold me-1 mb-0'>Straat:</p>
                          <p className='textBold mb-0'>{`${customer.address_streetname ? customer.address_streetname : ''} ${customer.address_house_number ? customer.address_house_number : ''}${customer.address_addition ? customer.address_addition : ''}`}</p>
                        </div>

                        <div className='d-flex flex-row'>
                          <p className='fw-semibold me-1 mb-0'>Adres:</p>
                          <p className='textBold mb-0'>{`${customer.address_postalcode ? customer.address_postalcode + ',' : ''} ${customer.address_city ? customer.address_city : ''}`}</p>
                        </div>

                        <div className='d-flex flex-row'>
                          <p className='fw-semibold me-1'>Land:</p>
                          <p className='textBold'>{`${customer.address_country ? customer.address_country : ''}`}</p>
                        </div>

                        <div className='d-flex flex-row flex-wrap'>
                          <p className='fw-semibold me-1 mb-0 text-nowrap'>E-mail:</p>
                          <p className='textBold mb-1 text-nowrap'>{`${customer.email}`}</p>
                        </div>

                        <div className='d-flex flex-row flex-wrap'>
                          <p className='fw-semibold me-1 mb-0'>Telefoonnummer:</p>
                          <p className='textBold'>{`${customer.tel_number ? customer.tel_number : ''}`}</p>
                        </div>
                        
                        <div className=' d-flex flex-row'>
                          <img src={Cake} alt="Taart" className='me-3 cakeIcon' />
                          <div>
                            <p className='textBold mb-0 birthDay'>{`${customer.date_of_birth ? new Date(customer.date_of_birth).toLocaleDateString('nl-NL', dateOptions) : ''}`}</p>
                            <p className='textBold mb-0'>{`${customer.date_of_birth ? `${getAge(customer.date_of_birth)} jaar` : ''}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=' d-flex flex-row justify-content-between parentBoxes tableNote'>
                    <div className='widthTablePunten p-4 ps-0 pt-0 mb-5'>
                      <h5 className='whiteBox mb-0 fw-bold titleTableViewCustomers'>Punten historie</h5>
                      <Table customer={customer}/>
                    </div>
                    <div className='whiteBox heightCustomerNote py-4 pe-4 ps-5'>
                      <h5 className='fw-bold'>Klantnotitie</h5>
                      <textarea rows="12" value={values.note} onChange={handleValues} onBlur={handleNote} name='note' cols="20" className='textareaStyle'></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const Table = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    "page_size": 5,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({orderBy: 'created_at', direction: 'desc'});

  const fetchAll = async () => {
    setLoading(true);
    const [transactionsResponse] = await Promise.all([
      loyalty.get(`transactions?customer_id=${props.customer.id}&page=${pagination.page}&page_size=${pagination.page_size}&orderBy=created_at&direction=desc`),
    ]);
    setData(transactionsResponse.data.data);
    setPagination({...pagination, "max_items": transactionsResponse.data.total, "begin_of_page": transactionsResponse.data.from, "end_of_page": transactionsResponse.data.to})
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchAll();
    } catch (error) {
      console.warn(error)
    }
  }, [pagination.page_size, pagination.page, order, order.direction]);

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: "Datum",
        accessor: "created_at",
        Cell: ({ row }) => {
          const date = new Date(row.original.created_at);
          const formattedDate = date.toLocaleDateString();
          const formattedTime = date.toLocaleTimeString();
          const formattedDateTime = <span>{formattedDate} <br/> {formattedTime}</span>;
          return formattedDateTime;
        }
      },
      {
        Header: "Bij/afboeking",
        accessor: "amount",
        Cell: ({ row }) => {
          if (row.original.amount > 0) {
            return `+${row.original.amount}`;
          }
          return row.original.amount;
        }
      },
      {
        Header: "Nieuwe saldo",
        accessor: "new_balance",
      },
      {
        Header: "Reden",
        accessor: "reason",
      },
    ],
    [order.orderBy, order.direction, selectedIds, setSelectedIds, data]
  );

  const tableInstance = useTable({ columns, data: data });

  return (
    <div className='viewCustomer'>
        {!loading ?
            <TablePreset data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination}]} setPagination={setPagination} handleSelection={handleSelection} primary_key={'id'}/>
        : <div className="table-container"><table><thead><tr><td><div>Loading</div></td></tr></thead></table></div>}
    </div>
  );
}

function getAge(date) {
  const today = new Date();

  const birthdayString = `${date}`;
  const birthday = new Date(birthdayString);

  let age = today.getFullYear() - birthday.getFullYear();

  if (today.getMonth() < birthday.getMonth() || (today.getMonth() == birthday.getMonth() && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
}

export default VieuwBusines;
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../Navbars/Sidebar/Sidebar';
import Navbar from '../../Navbars/Navbar/Navbar';
import loyalty from '../../Api/Loyalty';
import ToastError from '../../Toasts/ToastError';
import { ToastContainer, toast } from 'react-toastify';

const NewCustomer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const barcode = searchParams.get('barcode');

  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState({
    barcode: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    email: '',
    tel_number: '',
    address_postalcode: '',
    address_house_number: '',
    address_streetname: '',
    address_city: '',
    address_country: '',
    address_addition: '',
  });

  useEffect(() => {
    (async () => {
      if (parseInt(barcode).toString().length === 13) {
        try {
          const res = await loyalty.get(`customers?barcode=${barcode}`);

          if (res.data.length > 0) {
            navigate(`/klanten/${res.data[0].type === 0 ? 'consument' : 'bedrijf'}/${res.data[0].barcode}`);
          }

          setValues({...values, barcode: barcode});
          setIsLoading(false);
        } catch (error) {
          console.warn(error);
          navigate(`/klanten`);
        }
      } else {
        if (barcode) {
          navigate(`/klanten`);
        } else {
          await loyalty.get(`customers?id=0`);
          setIsLoading(false);
        }
      }
    })()
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (parseInt(values.barcode).toString().length !== 13) {
        ToastError(`Ongeldige kaartnummer`);
        return;
      }

      if (!isValidEmail(values.email)) {
        ToastError(`Ongeldige email`);
        return;
      }

      const realBarcode = await loyalty.get(`barcodes?barcode=${values.barcode}`);
      if (realBarcode.data.length === 0) {
        ToastError(`Ongeldige kaartnummer`);
        return;
      }

      const checkBarcode = await loyalty.get(`customers?barcode=${values.barcode}`);

      if (checkBarcode.data.length > 0) {
        ToastError(<span className='text-danger'>Barcode: <Link to={`/klanten/${checkBarcode.data[0].type === 0 ? 'consument' : 'bedrijf'}/${values.barcode}`}>{values.barcode}</Link> bestaat al</span>);
        return;
      }

      const checkEmail = await loyalty.get(`customers?email=${values.email}`);
      if (checkEmail.data.length !== 0) {
        ToastError(<span><a onClick={() => navigate(`/klanten/consument/${checkEmail.data[0].barcode}`)} className='link-primary hover'>{checkEmail.data[0].email}</a> bestaat al</span>);
        return;
      }

      await toast.promise(
        loyalty.post(`customers`, {
          ...values,
          type: 0,
        }),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
          error: 'Er is iets fout gegaan tijdens het opslaan'
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );

      navigate(`/klanten/consument/${values.barcode}`);
    } catch (error) {
      console.warn(error);
    }
  }

  function isValidEmail(email) {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    return emailRegex.test(email);
  }

  const handleChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value});
  }

  return (
    <div className='d-flex flex-nowrap'> 
      <Sidebar/>
      <div className='d-flex flex-column col-10'>
        <Navbar title={'KLANTEN'}/>
        <ToastContainer />
        <div className='d-flex'>
          {isLoading ? (
            <div className='mainBox'>
              <div className="d-flex px-3 py-2 mt-4 mx-4 col-10 justify-content-around flex-column position-absolute">
                <div className="spinner-border text-primary spinner-border"></div>
              </div>
            </div>
          ) : (
            <div className='mainBox'>
              <div className='d-flex px-4 py-2 mt-4 mx-5 col-10 justify-content-around flex-column position-absolute'>
                <div className='mb-4'>
                  <div className=' d-flex flex-row justify-content-between makeCustomer'>
                    <div className='whiteBox boxNieuwClient p-4 mb-5 me-5'>
                      <h3 className='fw-bold ps-3 mb-4'>Klant aanmaken</h3>
                      <form onSubmit={handleSubmit} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
                        <p className='inputTitle mb-0'>Kaartnummer</p>
                        <input value={values.barcode} onChange={handleChange} required name="barcode" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="number"></input>
                        <p className='inputTitle mb-0'>Voornaam</p>
                        <input value={values.first_name} onChange={handleChange} name="first_name" autoComplete="given-name" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="text"></input>
                        <p className='inputTitle mb-0'>Achternaam</p>
                        <input value={values.last_name} onChange={handleChange} name="last_name" autoComplete="family-name" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="text"></input>
                        <p className='inputTitle mb-0'>E-mailadres</p>
                        <input value={values.email} onChange={handleChange} required name="email" autoComplete="email" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="email"></input>
                        <p className='inputTitle mb-0'>Telefoonnummer</p>
                        <input value={values.tel_number} onChange={handleChange} name="tel_number" autoComplete="tel" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="tel"></input>
                        <p className='inputTitle mb-0'>Geboortedatum</p>
                        <input value={values.date_of_birth} onChange={handleChange} name="date_of_birth" className='mt-2 inputField inputFieldWidth form-control heightInputs pe-2 mb-3' type="date"></input>
                        <p className='inputTitle mb-0'>Postcode</p>
                        <input value={values.address_postalcode} onChange={handleChange} name="address_postalcode" autoComplete="postal-code" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="text"></input>
                        <div className='d-flex flex-row col-12 streetHouseNum'>
                          <div className=' col-5 me-4'>
                            <p className='inputTitle mb-0'>Straat</p>
                            <input value={values.address_streetname} onChange={handleChange} name="address_streetname" className='mt-2 inputField col-12 heightInputs mb-3 me-3 widthStreetInputs' type="text"></input>
                          </div>
                          <div className=' col-3'>
                            <p className='inputTitle mb-0'>Huisnummer</p>
                            <input value={values.address_house_number} onChange={handleChange} name="address_house_number" className='mt-2 col-12 inputField inputFieldWidth heightInputs widthStreetInputs mb-3' type="number"></input>
                          </div>
                          <div className=' col-3'>
                            <p className='inputTitle mb-0'>Toevoeging</p>
                            <input value={values.address_addition} onChange={handleChange} name="address_addition" className='mt-2 col-12 inputField inputFieldWidth widthStreetInputs heightInputs mb-3' type="text"></input>
                          </div>
                        </div>
                        <p className='inputTitle mb-0'>Stad</p>
                        <input value={values.address_city} onChange={handleChange} name="address_city" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-3' type="text"></input>
                        <p className='inputTitle mb-0'>Land</p>
                        <input value={values.address_country} onChange={handleChange} name="address_country" className='mt-2 inputField inputFieldWidth heightInputs pe-2 mb-4' type="text"></input>
                        <button className="btn addClientButton hover text-nowrap"  role="button">Consument aanmaken</button>
                      </form>
                    </div>
                    <div className='whiteBox heightCustomerNote newClientNote mt-0 py-4 pe-4 ps-5'>
                      <h5 className='fw-bold'>Klantnotitie</h5>
                      <textarea value={values.note} onChange={handleChange} name='note' rows="12" cols="20" className='textareaStyle'></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewCustomer;
import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import adminLoyalty from "../Api/AdminLoyalty";
import TablePreset from "../table/TablePreset";
import "../table/Table.css";
import "../MainCss/Main.css";
import "./Admin.css"
import ToastError from "../Toasts/ToastError";
import ToastSuccess from "../Toasts/ToastSuccess";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [uploaded, setUploaded] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await toast.promise(
        adminLoyalty.post('uploadBarcodes', formData),
        {
          pending: 'Opslaan',
          success: 'Succesvol opgeslagen',
          error: 'Er is iets fout gegaan tijdens het opslaan'
        },
        {
          autoClose: 5000,
          style: {
            top: '100px'
          }
        }
      );
      setUploaded(response.data);
    } catch (error) {
      console.error(error);
    }
    event.target.value = null;
  }

  const logout = () => {
    cookies.remove('adminToken', { path: '/admin'});
    navigate('/admin');
  }

  return (
    <div className='d-flex flex-nowrap bgColor'>
      <ToastContainer/>
      <div className='d-flex flex-column col-12'>
        <div className='d-flex m-3 justify-content-center'>
          <div className="d-flex flex-column divParent">
            <h1 className="text-center titleAdminPage">Admin Page</h1>
            {uploaded && <p className="text-center text-dark fw-bold titleAdminPage">Ververs de pagina om terug te gaan naar de tabel</p>}
            <div className="d-flex justify-content-end mb-3 me-3">
              <button onClick={logout} className="btnAdmin hover btn-large">Loguit</button>
            </div>
            <div className="d-flex justify-content-end mb-3 me-3">
              <input type="file" onChange={handleFileChange} accept=".xlsx,.csv" name="imageUpload" id="imageUpload" className="hide" />
              <label htmlFor="imageUpload" className="btnAdmin hover btn-large">Importeer Barcodes</label>
            </div>
            {!uploaded ? (
              <div>
                <Table/>
              </div>
            ) : (
              <div style={{maxHeight: '500px'}} className="d-flex table-container col-12 overflow-auto">
                <table style={{width: '400px'}}>
                  <thead>
                    <tr>
                      <th>Accepted: {uploaded.accepted.length}</th>
                    </tr>
                  </thead>
                  <tbody className="customTbody">
                    {uploaded.accepted.map((barcode, index) => (
                      <tr key={index} style={{borderTop: "1px solid lightgray"}}>
                        <td>{barcode}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table style={{width: '400px'}}>
                  <thead>
                    <tr>
                      <th>Declined: {uploaded.rejected.length}</th>
                    </tr>
                  </thead>
                  <tbody className="customTbody">
                    {uploaded.rejected.map((barcode, index) => (
                      <tr key={index} style={{borderTop: "1px solid lightgray"}}>
                        <td>{barcode}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const Table = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    "page_size": 5,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });

  const fetchAll = async () => {
    try {
      setLoading(true);
      const [consumerResponse] = await Promise.all([
        adminLoyalty.get(`barcodes?page=${pagination.page}&page_size=${pagination.page_size}&orderBy=id&direction=desc`),
      ]);
      setData(consumerResponse.data.data);
      setPagination({...pagination, "max_items": consumerResponse.data.total, "begin_of_page": consumerResponse.data.from, "end_of_page": consumerResponse.data.to})
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        ToastError(`Je moet ingelogt zijn om deze pagina te bezoeken`);
      } else {
        console.warn(error)
      }
      cookies.remove('adminToken', { path: '/admin'});
      navigate('/admin');
    }
  };

  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page]);

  const [selectedIds, setSelectedIds] = useState([]);

  const handleDelete = async (barcode) => {
    try {
      const result = window.confirm("Weet je zeker dat je deze barcode wilt verwijderen? Dit kan NIET ongedaan worden");
      if (!result) {
        return;
      }

      await adminLoyalty.delete(`barcodes?barcode=${barcode}`);
      ToastSuccess(`Succesvol verwijderd`);

      fetchAll();
    } catch (error) {
      if (error.response.status === 401) {
        ToastError(`Je moet ingelogt zijn om deze pagina te bezoeken`);
        cookies.remove('adminToken', { path: '/admin'});
        navigate('/admin');
      } else if (error.response.status === 400) {
        ToastError(error.response.data.error, 7000);
      } else {
        console.warn(error)
      }
    }
  }

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Barcode",
        accessor: "barcode",
      },
      {
        Header: " ",
        Cell: ({row}) => {
          return (
            <div className="text-end">
              <button onClick={() => handleDelete(row.original.barcode)} className="btn btn-danger">Verwijder</button>
            </div>
          )
        }
      }
    ],
    [selectedIds, setSelectedIds, data]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: data });
  
  const Action = (methode) => {
    if (methode == 'Select All') {
      setSelectedIds(...selectedIds, data.map(e => e.consumer_id))
    }
    else if (methode == 'De-select All') {
      setSelectedIds([])
    }
  }

  return (
    <>
      {!loading ?
        <TablePreset data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination}]} setPagination={setPagination} primary_key={'id'}/>
      : <div className="table-container"><table><thead><tr><td><div>Loading</div></td></tr></thead></table></div>}
    </>
  )
}

export default AdminDashboard;